import { merge } from 'lodash';

import * as getMove from './getMove';
import * as getMoveSummaries from './getMoveSummaries';
import * as createMove from './createMove';
import * as updateMove from './updateMove';
import * as updateMoveStep from './updateMoveStep';

export const initialState = merge(
  getMove.initialState,
  getMoveSummaries.initialState,
  createMove.initialState,
  updateMove.initialState,
  updateMoveStep.initialState,
);

export const reducers = [
  getMove.reducer,
  getMoveSummaries.reducer,
  createMove.reducer,
  updateMove.reducer,
  updateMoveStep.reducer,
];
