import { generate, request } from '@moved/services';

const creator = {
  namespace: 'MOVES_GET_MOVE',
  request: (id) => request.get(`/residents/moves/${id}`).then(r => r?.data?.data?.move),
  selector: (state, id) => state?.moves?.[id],
  reducers: {
    success: (state, { response: move, params: [id] }) => ({
      ...state,
      moves: {
        ...state.moves,
        [id]: move,
      },
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useMove,
  usePending: useMovePending,
} = generate.redux(creator);

export const initialState = {
  moves: {},
};
