import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom'

import { useNotify, format } from '@moved/services';
import { CompleteCard, FieldList, Button } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { Snippet } from '../../../../snippets';
import { useActiveTask } from '../../../contexts/TaskContext';
import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  CompleteScreenActions,
  ServiceProviderAd,
} from '../../shared';

export const Confirmation = ({screen, origin, taskBaseRoute}) => {
  // hooks
  const { id, moveId } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    activeTaskDefinition: taskDefinition,
    activeTaskDetails: taskDetails,
    activeTask,
  } = useActiveTask();

  const resetPending = taskDefinition.selectors.useResetMoverBookingTaskPending();

  const reset = () => {
    // Revert moving vendor option
    dispatch(taskDefinition.actions.reset(id))
      // refresh the move because reset affects MBT and MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then(resp => history.push(`${taskBaseRoute}${taskDefinition.getStartOverRoute(resp)}`))
      .catch(err => Notify.error(format.error(err)));
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} />
      <ScreenContent screen={screen}>

        <CompleteCard
          status='approved'
          flavor={(
            <Snippet tag={'tasks.book-movers.summary.diy'}>
              Your property does not require any additional information.
            </Snippet>
          )}
        >
          <FieldList list={[
            ...taskDefinition.helpers.getSummaryFields(taskDetails),
            {
              value: (
                <Button
                  size='small'
                  color='secondary'
                  text='Change selection'
                  onClick={reset}
                  disabled={resetPending}
                />
              )
            },
          ]} />
        </CompleteCard>

        { (taskDetails?.service_provider_ads ?? []).map(ad => (
          <ServiceProviderAd
            key={ad.id}
            className="marginBottom-24"
            ad={ad}
            buildingMoveTaskId={activeTask?.id}
          />
        ))}

      </ScreenContent>
      <CompleteScreenActions origin={origin} />
    </ScreenLayout>
  );
};
