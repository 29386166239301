import { Icon, Card, Button } from '@moved/ui';

import { useWizard, Content, Actions, SideImage } from './OnboardingWizard';

import dashboardPreview from './images/dashboard-preview.png';
import CSS from './StepWelcome.module.scss';

export const StepWelcome = () => {
  const { next } = useWizard();
  return (
    <>
      <Content>

        <div className='stackVertical gap-4'>
          <div className='headingL contentPrimary'>
            Move day is approaching – we're here to help!
          </div>
          <div className='labelM contentSecondary'>
            Before we get started, please provide a few details about your move so we can tailor your moving tasks.
          </div>
        </div>

        <div className={CSS.divider} />

        <div className='stackVertical gap-28'>

          <div className='stackHorizontal gap-12 items-start'>
            <Card className='padding-12 radius-full'>
              <Icon library='files' symbol='Clipboard-check' />
            </Card>
            <div className='stackVertical'>
              <div className='labelL contentPrimary'>
                Helpful reminders
              </div>
              <div className='labelS contentSecondary'>
                We've organized everything required to fulfill your lease obligations, and will remind you along the way.
              </div>
            </div>
          </div>

          <div className='stackHorizontal gap-12 items-start'>
            <Card className='padding-12 radius-full'>
              <Icon library='shopping' symbol='Sale#2' />
            </Card>
            <div className='stackVertical'>
              <div className='labelL contentPrimary'>
                Exclusive offers
              </div>
              <div className='labelS contentSecondary'>
                Take advantage of moving related services and offers personalized to your unique move.
              </div>
            </div>
          </div>

          <div className='stackHorizontal gap-12 items-start'>
            <Card className='padding-12 radius-full'>
              <Icon library='communication' symbol='Group-chat' />
            </Card>
            <div className='stackVertical'>
              <div className='labelL contentPrimary'>
                Friendly support
              </div>
              <div className='labelS contentSecondary'>
                If you have a question or get stuck, our moving concierge team is a click away.
              </div>
            </div>
          </div>

        </div>

        <div className={CSS.divider}/>

        <Actions className={CSS.actions}>
          <Button
            text='Get started'
            icon={{ library: 'navigation', symbol: 'Arrow-right' }}
            iconPosition='right'
            onClick={next}
          />
        </Actions>

      </Content>

      <SideImage src={dashboardPreview} />

    </>
  );
};
