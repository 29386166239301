import { Icon, Button } from '@moved/ui';
import { useModal } from '@moved/services';
import classNames from 'classnames';

import CSS from './StepComplete.module.scss';

export const StepComplete = () => {
  const Modal = useModal();
  const complete = () => Modal.close();
  return (
    <div className={classNames('stackVertical gap-40',CSS.content)}>

      <div className='stackVertical gap-4 items-center'>
        <Icon library='general' symbol='Approved' size='96px' color='green' />
        <div className='headingL contentPrimary'>
          Congrats, you’re all set!
        </div>
        <div className='labelM contentSecondary' style={{textAlign:'center'}}>
          Don't worry, you can change your mind later. This just helps us accommodate your situation better.
        </div>
      </div>


      <div className='stackHorizontal justify-center'>
        <Button
          text='View dashboard'
          onClick={complete}
        />
      </div>

    </div>
  );
};
