import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Card, Form, FormAddress, Icon } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

import CSS from './styles/MovingStops.module.scss';

export const MovingStops = ({ screen, changeScreen, task }) => {
  const { id, moveId } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const move = task.selectors.useMove(moveId);
  const taskDetails = task.selectors.useTaskable(id);
  const pending = task.selectors.useUpdateMoverBookingTaskPending();

  const moverBooking = taskDetails?.mover_booking ?? {};
  const initialOrigin = (
    moverBooking?.stops?.at?.(0) ?? // previously entered value
    (move?.move_steps ?? []).find(step => step.type === 'move-out')?.lease?.address // prepopulated from the lease data
  );
  const initialDestination = (
    moverBooking?.stops?.at?.(-1) ?? // previously entered value
    (move?.move_steps ?? []).find(step => step.type === 'move-in')?.lease?.address // prepopulated from the lease data
  );

  const submit = (data) => {
    const stops = [
      { address: data.origin },
      { address: data.destination },
    ];
    dispatch(task.actions.update(taskDetails.id, { mover_booking: { id: moverBooking.id, stops }}))
      .then(() => changeScreen('next'))
      .catch(err => Notify.error(format.error(err)));
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} />
      <ScreenContent screen={screen}>
        <Form
          name={'stops-form'}
          onSubmit={submit}
        >
          <div className={classNames('stackVertical gap-32', CSS.stopsList)}>
            <div className='stackHorizontal gap-20 items-center'>
              <Card className={CSS.stopIcon}>
                <Icon
                  library='map'
                  symbol='Origin'
                />
              </Card>
              <FormAddress
                name='origin'
                label='Origin address'
                value={initialOrigin?.google_place_id ? initialOrigin : {}}
                className='width-full'
              />
            </div>
            <div className='stackHorizontal gap-20 items-center'>
              <Card className={CSS.stopIcon}>
                <Icon
                  library='map'
                  symbol='Destination'
                />
              </Card>
              <FormAddress
                name='destination'
                label='Destination address'
                value={initialDestination?.google_place_id ? initialDestination : {}}
                className='width-full'
              />
            </div>
          </div>
        </Form>
      </ScreenContent>
      <ScreenActions>
        <Button
          text='Next'
          form={`stops-form`}
          disabled={pending}
        />
      </ScreenActions>
    </ScreenLayout>
  );
}
