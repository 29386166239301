import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { useTrack, hooks } from '@moved/services';

import { TaskLayout } from './TaskLayout';
import { getDashboardRoute } from '../../dashboard/helpers';

import { useActiveTask } from '../contexts/TaskContext';
import { ScreenLoading } from '../types/shared';
import { TaskScreenTransition } from './TaskScreenTransition';
import { TrainMobile } from './TrainMobile';
import { TrainDesktop } from './TrainDesktop';

const _injectCopyIntoFlow = (flow, copyMap) => {
  return flow.map(screen => {
    const copy = get(copyMap,`${screen.slug}`) || {};
    return { ...screen, ...copy };
  });
};

// Future: TaskFlow should be a context and all screens can get hooks to interact with the flow
// useNext(), usePrev(), useTaskDefinition()... etc.

// Main Taskflow Component
export const TaskFlow = () => {
  const Track = useTrack();
  const { moveId, stepId, taskType, id, screenSlug, screenContext } = useParams();
  const history = useHistory();
  const isMobile = hooks.useMobile();
  const {
    activeTaskDefinition,
    activeTaskDetails,
    activeTaskSummary,
    activeTaskBaseRoute,
  } = useActiveTask();

  const activeMoveStep = activeTaskDefinition.selectors.useActiveMoveStep(stepId);
  const building = activeTaskDefinition.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const taskDetails = activeTaskDefinition.selectors.useTaskable(id);

  const [flow, setFlow] = useState(_injectCopyIntoFlow(activeTaskDefinition.flow, get(building,`copy.tasks.${taskType}`)));
  const { flowNested } = activeTaskDefinition;

  const [currentIndex, setCurrentIndex] = useState();
  const [currentScreen, setCurrentScreen] = useState();
  const [nestedIndex, setNestedIndex] = useState(0);

  // convenience values
  const dashboardRoute = getDashboardRoute(moveId, stepId);

  // keep task flow up-to-date
  useEffect(() => {
    if(taskDetails) activeTaskDefinition.updateFlow(taskDetails);
    setFlow(_injectCopyIntoFlow(activeTaskDefinition.flow, get(building,`copy.tasks.${taskType}`)));
  },[taskDetails, activeTaskDefinition, building, taskType]);

  /* handle disallowed screens and no screen specified */
  useEffect(() => {
    // if no task details loaded, we can't determine the screen
    if(!taskDetails) return;
    // check if the requested screen is allowed, if not, redirect to recommended screen
    if(!screenSlug || !activeTaskDefinition.canAccessScreen(taskDetails, screenSlug, screenContext)) {
      return history.replace(`${activeTaskBaseRoute}${activeTaskDefinition.getRecommendedRoute(taskDetails,screenContext)}`);
    }
    // find and set the currently active screen from all screens available in the flow
    flow.some((screen,idx) => {
      if(screen.slug === screenSlug && (!screenContext || screen.context === parseInt(screenContext)) ) {
        setCurrentIndex(idx);
        setCurrentScreen(screen);
        Track.event(`${activeTaskDefinition.label} - Viewed ${screen.label}`);
        return true;
      }
      return false;
    });
    flowNested.some((screen,idx) => {
      if(screen.slug === screenSlug && (!screenContext || screen.context === parseInt(screenContext))) {
        setNestedIndex(idx);
        return true;
      } else if(screen.isCategory) {
        screen.screens.some(subScreen => {
          if(subScreen.slug === screenSlug && (!screenContext || subScreen.context === parseInt(screenContext))) {
            setNestedIndex(idx);
            return true;
          }
          return false;
        });
      }
      return false;
    })
  },[taskDetails, flow, screenSlug, screenContext]); // eslint-disable-line

  // Push to next screen unless back is specified
  const changeScreen = (direction, data) => {
    // - Important: Need to pass task data to this function if updating flow
    // - otherwise, run into race condition with redux updating
    if(data) activeTaskDefinition.updateFlow(data);
    const updatedFlow = activeTaskDefinition.flow;
    // find next screen in updated flow
    const newScreen = updatedFlow[direction === 'back' ? currentIndex-1 : currentIndex+1];
    // error handle if we somehow got out of bounds
    if(!newScreen) return history.push(`${activeTaskBaseRoute}${activeTaskDefinition.getRecommendedRoute(data||taskDetails,screenContext)}`);
    // if direction is next, event log the completion of current screen
    if(direction === 'next') Track.event(`${activeTaskDefinition.label} - Completed ${currentScreen.label}${currentScreen.context ? ' - '+currentScreen.context : ''}`);
    // trigger change of screen via history push
    return history.push(`${activeTaskBaseRoute}${activeTaskDefinition.getScreenRoute(newScreen,data||taskDetails)}`);
  };

  // convenience for navigation
  const close = () => history.push(dashboardRoute);
  const back = () => {
    const previousScreen = flow[currentIndex-1];
    if(!previousScreen || !activeTaskDefinition.canAccessScreen(taskDetails,previousScreen.slug)) return close();
    history.push(`${activeTaskBaseRoute}${activeTaskDefinition.getScreenRoute(previousScreen,taskDetails)}`);
  };

  const Train = isMobile ? TrainMobile : TrainDesktop;

  return (
    <TaskLayout origin={dashboardRoute} flowProgress={(
      <Train
        flow={flow}
        activeScreenIndex={currentIndex}
        nestedIndex={nestedIndex}
        back={back}
        close={close}
      />
    )}>
      <Helmet>
        <title>
          { `${get(currentScreen,'label','Loading')}${building?' - '+get(building,'settings.display_name',''):''} : Moved` }
        </title>
      </Helmet>
      { currentScreen ? (
        <TaskScreenTransition
          screen={currentScreen}
          index={currentIndex}
          changeScreen={changeScreen}
          task={activeTaskDefinition}
          // FUTURE: standardize these properties (and decide if all screens should just get them from context)
          taskDetails={activeTaskDetails}
          taskSummary={activeTaskSummary}
          taskDefinition={activeTaskDefinition}
          taskBaseRoute={activeTaskBaseRoute}
          origin={dashboardRoute}
        />
      ) : (
        <ScreenLoading />
      )}
    </TaskLayout>
  );
};
