import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Button, Card } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';
import { getServiceProviderAdRedirect } from '../actions';
import { useServiceProviderAdRedirectPending } from '../actions/selectors';

import CSS from './styles/ServiceProviderAd.module.scss';
import { isHexDark } from '@moved/services/src/utils/helpers';

export const ServiceProviderAd = ({
  className, // TODO remove this, and refactor the parent to use flex gap
  ad,
  buildingMoveTaskId
}) => {
  const dispatch = useDispatch();
  const redirectPending = useServiceProviderAdRedirectPending();

  const handleClick = () => {
    if (redirectPending) return;
    dispatch(getServiceProviderAdRedirect({
      buildingMoveTaskId: buildingMoveTaskId,
      serviceProviderAdId: ad.id,
    })).then(redirect => {
      window.open(decodeURIComponent(redirect.url));
    });
  };

  if (ad == null) {
    return null;
  }

  return (
    <section className={classNames('stackHorizontal gap-32', CSS.wrapper, className)}>

      <ResidentContentRenderer
        content={ad.description}
        className={CSS.contentBlock}
      />

      <Card className={CSS.ctaBlock}>
        <img
          className={CSS.logo}
          src={ad.logo_url}
          alt={`Ad for ${ad.name}`}
          aria-label={ad.name}
        />
        <p className='labelM contentSecondary marginBottom-16'>
          {ad.tagline}
        </p>
        <Button
          size='small'
          text={ad.cta_text}
          style={{backgroundColor: ad.cta_color}}
          className='width-full'
          color={ad.cta_color && isHexDark(ad.cta_color) ? 'primary' : 'secondary'}
          onClick={handleClick}
        />
      </Card>

    </section>
  );
};
