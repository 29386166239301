import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useUser, useNotify, useModal, format } from '@moved/services';
import { AuthenticationFormWrapper, AuthenticationForm, ForgotPasswordModal } from '@moved/product';

import { claimAccount } from '../actions';

export const ClaimAccountForm = ({ code, claim, onComplete, ...props }) => {

  const { updateUser } = useUser();
  const Notify = useNotify();
  const Modal = useModal();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const [existing, setExisting] = useState();
  const [matchExisting, setMatchExisting] = useState();
  const showEnterPassword = existing && matchExisting;

  const forgotPasswordModal = ({email}) => Modal.open(<ForgotPasswordModal email={email} />)

  const fields = [
    { label: 'First name', type: 'text', name: 'firstname', value: claim.firstname, required: true, half: true },
    { label: 'Last name', type: 'text', name: 'lastname', value: claim.lastname, required: true, half: true },
    { label: 'Email', type: 'email', name: 'email', value: claim.email, required: true, onChange: (event) => setMatchExisting(event?.target?.value === existing), },
    { label: 'Unit number', type: 'text', name: 'unit', value: claim.unit, readOnly: true, half: true },
    { label: `Lease ${claim.move_step_type === 'move-in' ? 'start' : 'end'} date`, type: 'text', name: 'lease_date', value: format.date(claim.lease_date), readOnly: true, half:true },
    { label: showEnterPassword ? 'Enter existing password' : 'Create password', type: 'password', name: 'password', value: '', required: 'Password is required', forgotPassword: showEnterPassword && forgotPasswordModal },
  ];

  const authenticate = ({ firstname, lastname, email, password }) => {
    if(pending) return;
    setPending(true);
    if(email) email = email.toLowerCase(); // force lowercase email
    dispatch(claimAccount(claim?.building_id, { firstname, lastname, email, password, claim_token: code }))
      .then((response) => {
        const { user, ...tokenData } = (response?.login_response ?? {});
        updateUser(user, tokenData);
        onComplete?.(response);
      })
      .catch(err => {
        if(err?.response?.status === 401) {
          setPending(false);
          setExisting(email);
          setMatchExisting(true);
          return Notify.error(
            `This email is already associated with a Moved account. Please double check your password and try again.`,
          );
        }
        return Notify.error(format.error(err));
      });
  };

  return (
    <AuthenticationFormWrapper display='signup' cta={'Confirm'} pending={pending} {...props}>
      <AuthenticationForm display='signup' fields={fields} authenticate={authenticate}  {...props} />
    </AuthenticationFormWrapper>
  )

};
