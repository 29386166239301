import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { useNotify } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { PageNotFound } from '../../common';
import { getMove } from '../actions';
import { useMove, useMovePending } from '../actions/selectors';

export const Move = ({ children }) => {
  // hooks
  const { moveId } = useParams();
  // redux
  const dispatch = useDispatch();
  const Notify = useNotify();
  const move = useMove(moveId);
  const pending = useMovePending();
  const [initialized, setInitialized] = useState();

  useEffect(() => {
    dispatch(getMove(moveId))
      .catch(() => Notify.default('Your move details could not be loaded.'))
      .finally(() => setInitialized(true));
  },[moveId,dispatch,Notify]);

  return (
    <>
     { pending && <LoaderOverlay/> }
     { move ? children : initialized && <PageNotFound /> }
    </>
  );
};

export const withMove = (Wrapped) => (props) => (<Move><Wrapped {...props}/></Move>);
