import { generate, request } from '@moved/services';

const creator = {
  namespace: 'MOVES_UPDATE_MOVE',
  request: (id,data) => request.patch(`/residents/moves/${id}`,data).then(r => r?.data?.data?.move),
  reducers: {
    success: (state, { response: move, params: [id] }) => ({
      ...state,
      moves: {
        ...state.moves,
        [id]: move,
      },
    }),
  },
};

export const {
  action,
  reducer,
  usePending: useUpdateMovePending,
} = generate.redux(creator);

export const initialState = {
  moves: {},
};
