import React from 'react';
import classNames from 'classnames';

import { Button } from '@moved/ui';

import CSS from './styles/CompareMovingMethods.module.scss';

export const CompareMovingMethods = () => (
  <div className={classNames('stackHorizontal gap-24 width-full',CSS.blue_box)}>
    Not sure which option is best for you?
    <a
      href='https://moved.com/blog/compare-moving-options'
      target='_blank'
      rel='noreferrer'
    >
      <Button
        text='Compare'
        size='small'
      />
    </a>
  </div>
);
