import { generate, request } from '@moved/services';

const creator = {
  namespace: 'MOVES_GET_RECOMMENDED_PERSONAL_TASK_TYPES',
  request: (id) => request.get(`/residents/moves/${id}/personal-task-lists/initial-recommended-personal-task-types`).then(r => r?.data?.data?.personal_task_types),
};

export const {
  action: getRecommendedPersonalTaskTypes,
  reducer,
  usePending: useRecommendedPersonalTaskTypesPending,
} = generate.redux(creator);

export const initialState = {};
